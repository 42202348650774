@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  height: 100%;

  @apply bg-gray-100;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
}

.empty {
  min-height: 5em;
}

.kbd {
  @apply bg-white border border-gray-200 rounded-sm shadow-sm text-black px-1 not-italic;
}

.profile {
  @apply flex flex-col items-center p-4 border border-gray-300 rounded-md;
}

.profile__name,
.profile__license {
  @apply mt-2 text-gray-800;
}

.profile__picture {
  @apply rounded-full overflow-hidden w-20 h-20 border-gray-400 border;
}

.emergency {
  @apply flex flex-col items-center p-4 border border-gray-300 rounded-md;
}

.emergency__name,
.emergency__number {
  @apply mt-2 text-gray-800;
}

.police {
  @apply rounded-md border border-gray-300 my-4 text-xs p-2;
}

.su-spoiler-title {
  display: none;
}

body.swal2-shown button.swal2-styled.swal2-cancel {
  @apply focus:outline-none focus:ring-2 focus:ring-opacity-50 text-white rounded-md shadow-md p-4 my-1;
  @apply bg-red-700 focus:bg-red-900 hover:bg-red-800 focus:ring-red-600;
}

body.swal2-shown .swal2-content {
  @apply p-0;
}

section {
  @apply relative;
}

form {
  @apply w-full;
}

@media (max-width: 600px) {
  .MuiContainer-root.MuiContainer-maxWidthSm {
    padding: 0;
  }

  .MuiContainer-root.MuiContainer-maxWidthSm > .MuiPaper-root {
    box-shadow: none;
    border: 0;
  }
}

.section__edit {
  @apply py-0 px-1 absolute right-2 top-1;
}

.reporting {
  @apply flex flex-wrap justify-center;
}

.reporting .button {
  @apply p-2 mx-2 text-sm;
}
